.header {
    position: relative;
    z-index: 101;
    background-color: white;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    border-top: 2px solid $primary;
    &__title {
        display: flex;
        flex-direction: column;
        & > a {
            display: flex;
            align-items: center;
            img {
                display: inline-block;
                margin-right: 0.3em;
            }
        }
    }
    &__toggler {
        &:focus {
            outline: 0;
            box-shadow: none;
        }
        &--icon {
            position: relative;
            display: block;
            width: 23px;
            height: 18px;
            span {
                display: inline-block;
                position: absolute;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $primary;
                transition: all 0.4s;
                &:nth-of-type(1) {
                    top: 0;
                }
                &:nth-of-type(2) {
                    top: 50%;
                    margin-top: -1px;
                }
                &:nth-of-type(3) {
                    bottom: 0;
                }
            }
            .in & {
                span {
                    &:nth-of-type(1) {
                        transform: translateY(8px) rotate(-315deg);
                    }
                    &:nth-of-type(2) {
                        opacity: 0;
                    }
                    &:nth-of-type(3) {
                        transform: translateY(-8px) rotate(315deg);
                    }
                }
            }
        }
    }
    &__logout {
        display: none;
    }

    @media (min-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: $header-height;
        &__title {
            flex-direction: row;
            align-items: center;
        }
        &__toggler {
            display: none;
        }
        &__logout {
            display: block;
            font-size: 0.9rem;
        }
    }
}
