$header-height: 58px !default;
$sidebar-width: 200px !default;

html,
body {
    height: 100%;
}

@import 'header';
@import 'sidebar';
@import 'main';
@import 'footer';
