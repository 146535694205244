@import "~bootstrap/scss/functions";

// Color system
$primary: #0b3363;
$danger: #ff0000;

// Body
$body-bg: #fbfbfb;
$body-color: #212529;

// Links
$link-color: $primary;
$link-hover-color: lighten($link-color, 15%);

// Typography
$font-family-sans-serif: "Noto Sans", '"Noto Sans CJK JP', sans-serif;

// tables
$table-dark-bg: $primary;

// buttons
$btn-border-radius: 9999px;

.btn-primary {
    letter-spacing: 0.5rem;
    text-indent: 0.5rem;
    min-width: 100px;
}

// Forms
$label-margin-bottom: 0;

// card
$card-cap-bg: none;
$card-cap-color: #6c757d;
$card-spacer-y: 1.5rem;

.card-header {
    border-width: 0 !important;
    padding-bottom: 1rem !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card-title {
        color: $card-cap-color;
        font-weight: 500;
        font-size: 1rem;
        margin-bottom: 0;
    }

    a {
        color: $card-cap-color;
    }
}
.card-body {
    padding-bottom: 1.5rem !important;
}

// breadcrumb
$breadcrumb-bg: $body-bg;
$breadcrumb-font-size: 0.875rem;
$breadcrumb-border-radius: 0;
$breadcrumb-divider-color: $body-color;
$breadcrumb-active-color: $link-hover-color;
$breadcrumb-margin-bottom: 0;

.breadcrumb {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    .mdi {
        vertical-align: middle;
    }
    .mdi::before {
        margin-right: 0.25rem;
        font-size: 1.2rem;
        line-height: 1;
    }
}
.breadcrumb-item:not(.active) a {
    color: $breadcrumb-divider-color;
}

// alert
$alert-border-width: 2px;
$alert-padding-y: 0.75rem;
$alert-padding-x: 1rem;
$alert-margin-bottom: 0;
$alert-border-level: 0;
$alert-color-level: 0;
