.main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    footer {
        margin-top: auto;
    }

    @media (min-width: 768px) {
        padding-top: $header-height;
        padding-left: $sidebar-width;
        -ms-flex: 1;
        flex: 1;
        height: 100%;
    }
}
