// 上下左右中央
.justify {
    width: 100%;
    padding: 50px 20px;
    @media (min-width: 768px) {
        padding: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
