// Fonts
@import url('//fonts.googleapis.com/css?family=Noto+Sans+JP:400,500');
@import url('//fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');

// Variables
@import 'variables';

// Bootstrap
@import 'app/bootstrap';

// Material Design Icons
@import '~@mdi/font/scss/materialdesignicons';

// Style
$header-height: 66px;
$sidebar-width: 0;
@import 'common/style';
@import 'common/justify';
